import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        logo: ('../assets/logo.png'),
        appName: 'India Property Dekho',

        // baseUrl: "http://api.ipd.localenv/api/",
        // baseUrl: "https://api-test.indiapropertydekho.com/api",
        baseUrl: "https://api.indiapropertydekho.com/api/",
        websiteLink: "https://api.indiapropertydekho.com/",
        // websiteLink: "http://api.ipd.localenv/",

  

        adminLogin: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")) : null,
        token: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).access_token : null,
        adminId: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).adminId : null,
        firstName: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).firstName : null,
        lastName: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).lastName : null,
        email: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).email : null,
        country_code: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).country_code : null,
        mobile: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).mobile : null,
        created_at: (localStorage.getItem("adminLogin")) ? JSON.parse(localStorage.getItem("adminLogin")).created_at : null,

        ability: (localStorage.getItem("ability")) ? JSON.parse(localStorage.getItem("ability")) : [],
        leadSearch: (localStorage.getItem("leadSearch")) ? JSON.parse(localStorage.getItem("leadSearch")) : [],
        userSearch: (localStorage.getItem("userSearch")) ? JSON.parse(localStorage.getItem("userSearch")) : [],
        propertySearch: (localStorage.getItem("propertySearch")) ? JSON.parse(localStorage.getItem("propertySearch")) : [],

        nextUrl: null,
        addPropertyParams: (localStorage.getItem("addPropertyParams")) ? JSON.parse(localStorage.getItem("addPropertyParams")) : null,

        pusher_app_key: "82799048beec716a34f9",
        pusher_cluster: "ap2",
        pusher_channel: "chat-channel",
        pusher_event: "chat-event",
    },

    // getters: {
    //
    // },
    getters: {
        loggedIn: state => {
            return state.token !== null
        },
        layout(state) {
            return state.layout
        },

        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
    },
    mutations: {
        destroyToken(state) {
            state.token = null
        },
        SET_LAYOUT(state, payload) {
            state.layout = payload
        }
    },
    actions: {
        // destroyToken(context) {
        //     axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        //
        //     if (context.getters.loggedIn) {
        //         return new Promise((resolve, reject) => {
        //             axios.post('/api/logout')
        //                 .then(response => {
        //
        //                     localStorage.removeItem('adminLogin')
        //                     context.commit('destroyToken')
        //                     resolve(response)
        //                 })
        //                 .catch(error => {
        //                     localStorage.removeItem('adminLogin')
        //                     context.commit('destroyToken')
        //                     reject(error)
        //                 })
        //         })
        //     }
        // },
    },
    modules: {}
})
